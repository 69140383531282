import { default as _91_46_46_46slug_93XCgNlrOoyLMeta } from "/cac/pages/[...slug].vue?macro=true";
import { default as footerUx6EYB0XJZMeta } from "/cac/pages/components/footer.vue?macro=true";
import { default as _91_46_46_46slug_93gsdnMP1U8bMeta } from "/cac/pages/iframe/[...slug].vue?macro=true";
import { default as searchr62GaCByi4Meta } from "/cac/pages/iframe/search.vue?macro=true";
import { default as _91_46_46_46slug_9356qDu76ToNMeta } from "/cac/pages/open-house-chicago/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_932A7EA3QiyHMeta } from "/cac/pages/preview/[...slug].vue?macro=true";
import { default as tnewCTC6xU6SBLMeta } from "/cac/pages/tnew.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/cac/pages/[...slug].vue")
  },
  {
    name: "components-footer",
    path: "/components/footer",
    meta: footerUx6EYB0XJZMeta || {},
    component: () => import("/cac/pages/components/footer.vue")
  },
  {
    name: "iframe-slug",
    path: "/iframe/:slug(.*)*",
    meta: _91_46_46_46slug_93gsdnMP1U8bMeta || {},
    component: () => import("/cac/pages/iframe/[...slug].vue")
  },
  {
    name: "iframe-search",
    path: "/iframe/search",
    meta: searchr62GaCByi4Meta || {},
    component: () => import("/cac/pages/iframe/search.vue")
  },
  {
    name: "open-house-chicago-slug",
    path: "/open-house-chicago/:slug(.*)*",
    component: () => import("/cac/pages/open-house-chicago/[...slug].vue")
  },
  {
    name: "preview-slug",
    path: "/preview/:slug(.*)*",
    component: () => import("/cac/pages/preview/[...slug].vue")
  },
  {
    name: "tnew",
    path: "/tnew",
    component: () => import("/cac/pages/tnew.vue")
  },
  {
    name: "/open-house-chicago/photo-competition-2024",
    path: "/open-house-chicago/photo-competition-2024",
    component: () => import("/cac/pages/[...slug].vue")
  },
  {
    name: "/open-house-chicago/archives",
    path: "/open-house-chicago/archives",
    component: () => import("/cac/pages/[...slug].vue")
  },
  {
    name: "/open-house-chicago/about",
    path: "/open-house-chicago/about",
    component: () => import("/cac/pages/[...slug].vue")
  },
  {
    name: "/open-house-chicago/volunteer",
    path: "/open-house-chicago/volunteer",
    component: () => import("/cac/pages/[...slug].vue")
  },
  {
    name: "/open-house-chicago/site-resources",
    path: "/open-house-chicago/site-resources",
    component: () => import("/cac/pages/[...slug].vue")
  }
]