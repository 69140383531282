import { testInternalLink, formatUrl } from '~/composables/helpers/links';

import { getEnv } from '@/composables/helpers/getEnv';

export const redirect = async (data, event = false) => {
 if (!data) return;
 if (!data.destination) return;
 const internalLink = testInternalLink(data.destination);

 if (internalLink) {
  const url = formatUrl(data.destination, data.destination.startsWith('/'));
  if (import.meta.client) {
   return await navigateTo(url, {
    redirectCode: data.code | 301,
    // replace: true,
   });
  } else if (event) {
   // https:github.com/nuxt/nuxt/issues/15652#issuecomment-1397423575
   return await sendRedirect(event, data.destination, data.code | 301);
  } else {
   let getEvent = null;
   try {
    getEvent = useRequestEvent();
   } catch (error) {
    throw createError({
     statusCode: 500,
     statusMessage: 'Error redirecting',
     fatal: true,
    });
   }
   // https:github.com/nuxt/nuxt/issues/15652#issuecomment-1397423575
   return await sendRedirect(getEvent, data.destination, data.code | 301);
  }
 } else {
  if (import.meta.client) {
   return await navigateTo(data.destination, {
    redirectCode: data.code | 301,
    replace: true,
    external: true,
   });
  } else if (event) {
   // https:github.com/nuxt/nuxt/issues/15652#issuecomment-1397423575
   return await sendRedirect(event, data.destination, data.code | 301);
  } else {
   let getEvent = null;
   try {
    getEvent = useRequestEvent();
   } catch (error) {
    throw createError({
     statusCode: 500,
     statusMessage: 'Error redirecting',
     fatal: true,
    });
   }
   // https:github.com/nuxt/nuxt/issues/15652#issuecomment-1397423575
   return await sendRedirect(getEvent, data.destination, data.code | 301);
  }
 }
};

export const checkRedirects = async (path) => {
    const builderApi = getEnv('builderApi');
    
    let isIframe = false;
    
    let altPath = path + '/';
    if (path.endsWith('/')) altPath = path.slice(0, -1);
    
    if (path.startsWith('/iframe') || path.startsWith('iframe')) {
      isIframe = true;
      altPath = altPath.replace('/iframe', '');
      altPath = altPath.replace('iframe', '');
      path = path.replace('/iframe', '');
      path = path.replace('iframe', '');
    }
    

    const response = await $fetch('https://cdn.builder.io/api/v3/content/redirects', {
        method: 'GET',
        headers: {
            Accept: '*/*',
        },
        query: {
            apiKey: builderApi,
            'query.data.target.$or': [path || '', altPath || ''],
            limit: '1',
            fields: 'data',
            cacheSeconds: 1,
            'builder.noCache': true,
            now: new Date(),
            'builder.cacheBust': true,
        },
    });


    if (response?.results?.length) {
        if (isIframe) {
          let {destination} = response.results[0].data;
          if (destination.startsWith('/')) destination = destination.slice(1);

          response.results[0].data.destination = '/iframe/' + destination;
        }
        return response.results[0].data
    }  
    
    return false;
};
